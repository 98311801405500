import React, { useState } from 'react';
import styled from 'styled-components';

const WaitlistContainer = styled.section`
  padding: 4rem 2rem;
  background-color: #ffffff;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  padding: 0.8rem;
  font-size: 1rem;
  width: 250px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 0.5rem;
`;

const Button = styled.button`
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const WaitlistSection = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = () => {
    if (email) {
      setSubmitted(true);
      // Handle email submission here
    }
  };

  return (
    <WaitlistContainer id="waitlist">
      <Title>Be the First to Experience the Future of Sales</Title>
      <Input 
        type="email" 
        placeholder="Enter your email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
      />
      <Button onClick={handleSubmit}>Join Waitlist</Button>
      {submitted && <p>Thank you for joining the waitlist!</p>}
    </WaitlistContainer>
  );
};

export default WaitlistSection;
