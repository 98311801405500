import React from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import heroAnimation from '../assets/animations/hero-animation.json';

const HeroContainer = styled.section`
  display: flex;
  align-items: center;
  padding: 4rem 2rem;
  background-color: #f9f9f9;
`;

const HeroText = styled.div`
  flex: 1;
  padding-right: 2rem;

  h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2rem;
  }
`;

const CTAButton = styled.button`
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const HeroSection = () => (
  <HeroContainer>
    <HeroText>
      <h1>Transforming Sales Cycles with AI-Powered Engagement</h1>
      <p>Streamline your sales process, target high-converting prospects, and boost revenue with GetCustomer.ai.</p>
      <CTAButton>Request a Demo</CTAButton>
    </HeroText>
    <Lottie animationData={heroAnimation} style={{ width: '400px', height: '400px' }} />
  </HeroContainer>
);

export default HeroSection;
