import React from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import featuresAnimation from '../assets/animations/features-animation.json';

const FeaturesContainer = styled.section`
  padding: 4rem 2rem;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeaturesContent = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 90%;
  margin-top: 2rem;
`;

const FeatureCard = styled.div`
  flex: 1;
  max-width: 300px;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }

  h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    color: #666;
  }
`;

const AnimationWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin: 0 auto 1.5rem;
`;

const FeaturesSection = () => (
  <FeaturesContainer>
    <h2>Our Key Features</h2>
    <FeaturesContent>
      <FeatureCard>
        <AnimationWrapper>
          <Lottie animationData={featuresAnimation} loop={true} />
        </AnimationWrapper>
        <h3>AI-Driven Engagement</h3>
        <p>Engage with your prospects in real-time directly within emails.</p>
      </FeatureCard>
      <FeatureCard>
        <AnimationWrapper>
          <Lottie animationData={featuresAnimation} loop={true} />
        </AnimationWrapper>
        <h3>Insightful Summaries</h3>
        <p>Get a summary of each prospect’s unique needs and inquiries.</p>
      </FeatureCard>
      <FeatureCard>
        <AnimationWrapper>
          <Lottie animationData={featuresAnimation} loop={true} />
        </AnimationWrapper>
        <h3>Reduced Sales Cycles</h3>
        <p>Automate repetitive tasks to save time and increase conversions.</p>
      </FeatureCard>
    </FeaturesContent>
  </FeaturesContainer>
);

export default FeaturesSection;
