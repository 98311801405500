import React from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import FeaturesSection from './components/FeaturesSection';
import WaitlistSection from './components/WaitlistSection';
import Footer from './components/Footer';

const AppContainer = styled.div`
  font-family: Arial, sans-serif;
`;

function App() {
  return (
    <AppContainer>
      <Header />
      <HeroSection />
      <AboutSection />
      <FeaturesSection />
      <WaitlistSection />
      <Footer />
    </AppContainer>
  );
}

export default App;
