import React from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import emailAnimation from '../assets/animations/email-animation.json'; // Add a relevant Lottie animation

const AboutContainer = styled.section`
  padding: 6rem 2rem;
  background: linear-gradient(135deg, #f0f4ff, #e6efff);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AboutContent = styled.div`
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #333;

  h2 {
    font-size: 2.5rem;
    color: #1a73e8;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    color: #555;
    max-width: 90%;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
`;

const AnimationWrapper = styled.div`
  width: 250px;
  height: 250px;
  margin-bottom: 2rem;
`;

const Highlights = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const HighlightCard = styled.div`
  flex: 1;
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-10px);
  }

  h3 {
    font-size: 1.4rem;
    color: #1a73e8;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    color: #555;
  }
`;

const AboutSection = () => (
  <AboutContainer>
    <AboutContent>
      {/* <AnimationWrapper>
        <Lottie animationData={emailAnimation} loop={true} />
      </AnimationWrapper> */}
      <h2>Empowering Sales Teams with AI</h2>
      <p>
        At GetCustomer.ai, we're driven to remove roadblocks in the sales cycle, allowing your team to focus on
        prospects that matter most. With our AI-driven email assistant, engage customers faster, answer their questions
        instantly, and schedule demos effortlessly. 
      </p>

      <Highlights>
        <HighlightCard>
          <h3>Streamlined Sales Process</h3>
          <p>Eliminate repetitive tasks and focus on meaningful interactions with potential clients.</p>
        </HighlightCard>
        <HighlightCard>
          <h3>Intelligent Engagement</h3>
          <p>Use AI-driven insights to connect with prospects and respond to their unique needs in real time.</p>
        </HighlightCard>
        <HighlightCard>
          <h3>Boosted Conversion Rates</h3>
          <p>Focus on high-value leads and shorten the time to conversion with optimized communication.</p>
        </HighlightCard>
      </Highlights>
    </AboutContent>
  </AboutContainer>
);

export default AboutSection;
