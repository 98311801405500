import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 1.5rem;

  a {
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: color 0.3s;

    &:hover {
      color: #007bff;
    }
  }
`;

function Header() {
  return (
    <HeaderContainer>
      <Logo>GetCustomer.ai</Logo>
      <NavLinks>
        <a href="#features">Features</a>
        <a href="#about">About Us</a>
        <a href="#waitlist">Join Waitlist</a>
      </NavLinks>
    </HeaderContainer>
  );
}

export default Header;
